import styled from 'styled-components'
import React from 'react';

const Sale = () => {
    return (
        <Container>
            <div className='wrapper'>
                <h1>Black Friday</h1>
                <p>Скидка <span>60%</span> - до конца Пятницы!</p>
            </div>

        </Container>
    )
}

export default Sale;

const Container = styled.div`
    padding: 30px;
    margin-top: 0;
    position: relative;
    text-shadow: 2px 2px 0 rgba(0,0,0,.05);
    transform: none;
    background-image: linear-gradient(180deg, #0252b8 0%, #3583ea 100%);
    border-bottom: 10px solid rgba(0,0,0,.03);
    text-align: left;
    z-index: 69;
    .wrapper {
        max-width: 373px;
        margin: 0 auto;
        color: #fff;
        h1 {
            line-height: 1.2;
            font-family: "poppins_bold";
            font-weight: 700;
            font-size: 40px;
            border-bottom: 2px solid rgba(0,0,0,.1);
            padding-bottom: 10px;
            margin-bottom: 5px;
        }
        p {
            margin: 0;
            line-height: 1.2;
            font-weight: 500;
            font-size: 24px;
        }
        span {
            color: #FFC600;
        }
    }
`;